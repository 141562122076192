<template>
    <div>
        <h3>Book your Teaching Sessions</h3>
        <iris-appointment-block v-bind="appointmentBlockProps" @slotsUnbooked="updateSlots" />
        <div v-if="slots === 0" class="mt-2">
            <b-button variant="primary" @click="$emit('ok')">Book & Complete</b-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DateTime, Duration, Interval } from 'luxon'
import type { IrisStore } from '~~/iris/src/store/types'

const store: IrisStore = getCurrentInstance().proxy.$store

const slots = ref<number>(-1)

export type AppointmentBlockProps = {
        /** subscriber id */
        subscriberId: number
        /** subscribers email */
        email: string
        /** first name */
        firstName: string
        /** last name  */
        lastName: string
        /** number of slots available */
        slotsAvailable: number
        /** time period allowed */
        interval?: Interval
        /** optional invoice id */
        invoiceId?: string
        /** plan string */
        planCode: string
}

const appointmentBlockProps = computed<AppointmentBlockProps>(() => {
    return {
        subscriberId: store.state.createAccountResult.subscriberId,
        email: store.state.familyInformation.email,
        firstName: store.state.familyInformation.firstName,
        lastName: store.state.familyInformation.lastName,
        interval: Interval.after(DateTime.now().startOf('day'), Duration.fromISO(store.getters.currentNewPricingPlan.billingCycleLength.toISOString())),
        slotsAvailable: store.getters.currentNewPricingPlan?.includedSessions ?? 0,
        planCode: store.state.finance.paymentMethod
    }
})

const updateSlots = (evt: number) => {
    slots.value = evt
}

    // const props = defineProps<{
    //     /** subscriber id */
    //     subscriberId: number
    //     /** subscribers email */
    //     email: string
    //     /** first name */
    //     firstName: string
    //     /** last name  */
    //     lastName: string
    //     /** number of slots available */
    //     slotsAvailable: number
    //     /** time period allowed */
    //     interval: Interval
    // }>()


</script>